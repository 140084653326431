$c-front : #696969;
$c-hilight : #1990ff;

.svg-calLoader {
  width: 230px; height: 230px;
  transform-origin: 115px 115px;
  animation: 1.4s linear infinite loader-spin;
}

.cal-loader__plane { fill: $c-hilight; }
.cal-loader__path { stroke: $c-front; animation: 1.4s ease-in-out infinite loader-path; }

@keyframes loader-spin {
  to{
    transform: rotate(360deg);
  }
}
@keyframes loader-path {
  0%{
    stroke-dasharray:  0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
  50%{
    stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
  }
  100%{
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
}