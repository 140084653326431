.ant-picker-panel-container {
  border-radius: 10px;
}


.ant-picker-ok {
  .ant-btn {
    border-radius: 5px;
  }
}

.ant-picker-dropdown {
  border-radius: 10px;

  .ant-picker-panel-container {
    border-radius: 10px;

    .ant-picker-panel {
      border-radius: 10px;
    }
  }
}

@media(max-width: 743px) {
  .ant-picker-datetime-panel {
    flex-direction: column;
  }

  .ant-picker-header {
    border-top: 1px solid #f0f0f0;
  }

  .ant-picker-panel {
    transform: scale(1) !important;
  }

  .ant-picker-content {
    flex-direction: column;
    height: auto !important;

    & > ul {
      display: flex;
      flex-direction: row;
      max-height: 30px;
      border-left: none !important;
      width: 270px;
      overflow-y: hidden !important;

      & > li {
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          padding: 0 7px !important;
        }
      }
    }
  }
}